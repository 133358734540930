font-face {
    src: url("./SourceSansPro-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("./SourceSansPro-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("./SourceSansPro-SemiBold.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("./SourceSansPro-Bold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("./SourceSansPro-Black.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Lora";
    src: url("./Lora-Italic-VariableFont_wght.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Lora";
    src: url("./Lora-VariableFont_wght.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
body {
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
