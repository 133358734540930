.App {
    text-align: center;
    max-width: 1300px;
    margin: 0 auto;
}
.header {
    padding: 20px;
    display: flex;
    height: 58.98px;
    max-width: 1045px;
    align-items: center;
    margin: 0 auto;
}
.appBarLogo {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0px;
}
.logoImg {
    width: 133px;

    max-height: 58.98px;
    width: auto;
    max-width: 100%;
}
.appBarLogoWrapper {
    height: 25px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    display: flex;
}
.appBarSocial {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}
.section1 h1 {
    box-sizing: border-box;
    width: 74.995%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 17px;
    padding-bottom: 17px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0em;
    line-height: 1.3em;
    text-decoration: none;
    text-transform: none;
    color: #000;
    font-size: 30px;
}
.section1banner {
    position: relative;
    padding: 17px;
    /* margin-top: 4%;
    margin-bottom: 4%; */
    /* margin-bottom: 500px; */
    max-width: 1045px;
    margin: 0 auto;
    margin-bottom: 550px;
}
.section1bannerImage img {
    left: 0.0555556px;
    top: 0px;
    width: 960px;
    height: 540px;
    position: absolute;
}
.name1Card {
    position: absolute;
    width: 50%;
    background-color: #ffe100;
    padding: 64px;
    box-sizing: border-box;
    text-align: left;
    right: -180px;
    top: 150px;
}
.name1Title {
    font-family: Source Sans Pro;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0em;
    text-transform: none;
    line-height: 1em;
    font-size: 20px;
}
.name1Title p {
    margin: 0px;
}
.name1Text {
    line-height: 1em;
    font-family: "Lora";
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    letter-spacing: 0em;
    margin-top: 25px;
}
.section2bannerImage img {
    max-width: 1300px;
}
.section3bannerImage img {
    max-width: 1300px;
    margin-left: -32px;
}
.italic {
    font-style: italic;
}
.boldText {
    font-family: "Lora";
    font-weight: 600;
}
.sessioncontactbanner {
    padding: 250px;
    /* margin: auto; */
    /* vertical-align: middle; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Source Sans Pro";
}

.contactTitle {
    font-family: "Source Sans Pro";
    font-size: 32px;
    font-weight: 600;
}
.contactEmail,
.contactPhone {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    font-size: 26px;
}

.emailID {
    margin-left: 15px;
    text-decoration: underline;
}
.phone {
    margin-left: 15px;
}
.pointer {
    cursor: pointer;
}
